<template>
  <div class="page-nominations">
    <Hero />

    <RanksFilters
      ref="ranksFilters"
      :ranks="titles"
      :countries="countries"
      :cities="cities"
      :names="names"
      :filters.sync="filtersInput"
    />

    <template v-for="(value, key) in modifiersMap">
      <RankWrapper
        v-show="isShow(key)"
        v-if="key === 'platinum executive'"
        :key="key"
        :title="key"
        :class="value"
      >
        <SpeakerSlider :items="getMastersList('platinum executive')" />
      </RankWrapper>

      <RankWrapper
        v-show="isShow(key)"
        v-else
        :key="key"
        :title="key"
        :class="value"
      >
        <div class="speaker-list">
          <div
            v-for="(master, index) in getMastersList(key)"
            :key="index"
            class="speaker-list-item"
          >
            <v-lazy
              :options="{
                threshold: .5
              }"
              transition="fade-transition"
            >
              <Card :master="master" />
            </v-lazy>
          </div>
        </div>
      </RankWrapper>
    </template>
  </div>
</template>

<script>
import Card from './components/Card'
import Hero from './components/Hero'
import RankWrapper from './components/RankWrapper'
import RanksFilters from './components/RanksFilters'
import SpeakerSlider from './components/SpeakerSlider'
import store from './store'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
  store,
  components: {
    Hero,
    SpeakerSlider,
    RankWrapper,
    RanksFilters,
    Card
  },

  data: () => ({
    noMatch: false
  }),

  computed: {
    ...mapGetters([
      'getMastersList',
      'titles'
    ]),

    ...mapState([
      'filters',
      'countries',
      'cities',
      'names'
    ]),

    modifiersMap () {
      const customMap = {
        'platinum executive': [
          'rank-wrapper--gray-background-filled-crown',
          'rank-wrapper--last-of-group'
        ],
        'gold executive': [
          'double-silver-executive',
          'rank-wrapper--white-background-filled-crown'
        ],
        'double silver executive': [
          'double-silver-executive',
          'rank-wrapper--white-background-filled-crown'
        ],
        'silver executive': [
          'silver-executive',
          'rank-wrapper--white-background-filled-crown'
        ],
        '20k executive': [
          'rank-wrapper--gradient-background-outlined-crown',
          'rank-wrapper--first-of-group'
        ],
        executive: [
          'rank-wrapper--gray-background-outlined-crown',
          'rank-wrapper--last-of-group'
        ],
        'diamond director': 'rank-wrapper--white-background-outlined-crown',
        'emerald director': [
          'rank-wrapper--gray-background-outlined-crown',
          'rank-wrapper--first-of-group'
        ],
        'gold director': [
          'rank-wrapper--gradient-background-outlined-crown',
          'rank-wrapper--last-of-group'
        ]
      }

      const preparedKeys = Object.keys(customMap)

      const otherMap = Object.fromEntries(
        this.titles
          .filter(el => !preparedKeys.includes(el))
          .map(el => ([el, 'rank-wrapper--white-background-outlined-crown']))
      )

      const allMasters = { ...customMap, ...otherMap }
      return this.filters.rank
        ? { [this.filters.rank]: allMasters[this.filters.rank] }
        : allMasters
    },

    filtersInput: {
      get () {
        return this.filters
      },
      set (val) {
        this.setFilters(val)
      }
    }
  },

  watch: {
    $route: {
      handler () {
        this.selectRankFilterByHash()
      },
      immediate: true
    },

    titles () {
      this.selectRankFilterByHash()
    }
  },

  mounted () {
    this.getMasters()
  },

  methods: {
    ...mapActions(['getMasters']),
    ...mapMutations(['setFilters']),

    selectRankFilterByHash () {
      const rankFilter = this.$route.hash.slice(1).replace(/_/g, ' ')
      if (!rankFilter || !this.titles?.length) return

      if (this.titles.includes(rankFilter)) {
        this.setFilters({
          ...this.filters,
          rank: rankFilter
        })
        this.$nextTick(() => {
          this.$refs.ranksFilters.$el.scrollIntoView()
        })
      } else {
        this.$router.replace(this.$route.path)
      }
    },

    isShow (key) {
      return !!this.getMastersList(key)?.length
    }
  }
}
</script>

<style lang="scss">
.page-nominations {
  .container {
    padding-top: 0;
    padding-bottom: 0;
  }

  .col-12 {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.speaker-list-item {
  position: relative;
  width: 20%;
  padding-left: 12px;
  padding-right: 12px;
}

.speaker-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -12px;
  margin-right: -12px;
  margin-top: 30px;

  .speaker-list-item {
    margin-top: 60px;
  }

  .speaker-list-item--small-img {
    margin-top: 40px;
  }
}

.rank-wrapper--gray-background-filled-crown {
  .rank-wrapper-tr-text {
    -webkit-text-stroke: 1px rgba(37, 37, 37, 0.1);
  }

  .rank-wrapper-inner {
    background-color: rgba(37, 37, 37, 0.05);
  }

  .rank-wrapper-content {
    padding-bottom: 140px;
  }
}

.rank-wrapper--white-background-filled-crown {
  padding-bottom: 140px;

  @media screen and (max-width: 1000px) {
    padding-bottom: 60px;
  }

  .rank-wrapper-tr-text {
    -webkit-text-stroke: 1px rgba(37, 37, 37, 0.1);
  }
}

.rank-wrapper--gradient-background-outlined-crown {
  .rank-wrapper-tr-text {
    -webkit-text-stroke: 1px rgba(255, 255, 255, 0.3);
  }

  .rank-wrapper-inner {
    background-image: var(--rank-wrapper--diamond-director-bg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .rank-wrapper-title {
    color: #FFF;
  }

  .rank-icon__icon {
    background-image: var(--crown-empty-white);
  }

  .rank-wrapper-content {
    padding-bottom: 140px;
  }

  .speaker-item__name a {
    color: #FFF;
  }

  .speaker-item__status {
    color: #FFF;
  }
}

.rank-wrapper--gray-background-outlined-crown {
  .rank-wrapper-tr-text {
    -webkit-text-stroke: 1px rgba(37, 37, 37, 0.1);
  }

  .rank-wrapper-inner {
    background-color: rgba(37, 37, 37, 0.05);
  }

  .rank-wrapper-title {
    color: #252525;
  }

  .rank-icon__icon {
    background-image: var(--crown-empty);
  }

  .rank-icon__text {
    color: var(--main-primary-background-color);
  }

  .rank-wrapper-content {
    padding-bottom: 130px;
    padding-top: 120px;
  }
}

.rank-wrapper--white-background-outlined-crown {
  .rank-wrapper-tr-text {
    -webkit-text-stroke: 1px rgba(37, 37, 37, 0.1);
  }

  .rank-wrapper-title {
    color: #252525;
  }

  .rank-icon__icon {
    background-image: var(--crown-empty);
  }

  .rank-icon__text {
    color: var(--main-primary-background-color);
  }

  .rank-wrapper-content {
    padding-top: 100px;
  }
}

.rank-wrapper--gray-background-filled-crown,
.rank-wrapper--gradient-background-outlined-crown,
.rank-wrapper--gray-background-outlined-crown,
.rank-wrapper--white-background-outlined-crown,
.rank-wrapper--white-background-filled-crown {
  border-radius: 20px;
  overflow: hidden;
}

.rank-wrapper--first-of-group {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.rank-wrapper--middle-of-group {
  border-radius: 0;
}

.rank-wrapper--last-of-group {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

@media (max-width: 1902.98px) {
  .speaker-list-item {
    width: 25%;
  }
}

@media (max-width: 1480px) {
  //
}

@media (max-width: 1264px) {
  .speaker-list-item {
    width: 33.33%;
  }

  .rank-wrapper--gray-background-filled-crown {
    .rank-wrapper-content {
      padding-bottom: 100px;
    }
  }

  .rank-wrapper--gradient-background-outlined-crown {
    margin-top: 100px;

    .rank-wrapper-content {
      padding-bottom: 100px;
    }
  }

  .rank-wrapper--gray-background-outlined-crown {
    .rank-wrapper-content {
      padding-bottom: 100px;
      padding-top: 100px;
    }
  }

  .rank-wrapper--white-background-outlined-crown {
    .rank-wrapper-content {
      padding-top: 100px;
    }
  }
}

@media (max-width: 960px) {
  .speaker-list-item {
    width: 50%;
  }

  .speaker-list {
    .speaker-list-item {
      margin-top: 30px;
    }
  }

  .rank-wrapper--gray-background-filled-crown {
    overflow: hidden;

    .rank-wrapper-content {
      padding-bottom: 70px;
    }
  }

  .rank-wrapper--gradient-background-outlined-crown {
    margin-top: 70px;

    .rank-wrapper-content {
      padding-bottom: 70px;
    }
  }

  .rank-wrapper--gray-background-outlined-crown {
    .rank-wrapper-content {
      padding-bottom: 70px;
      padding-top: 70px;
    }
  }

  .rank-wrapper--white-background-outlined-crown {
    .rank-wrapper-content {
      padding-top: 60px;
    }
  }
}

@media (max-width: 600px) {
  .speaker-list {
    margin-left: -12px;
    margin-right: -12px;
  }

  .speaker-list-item {
    padding-left: 8px;
    padding-right: 8px;

    .speaker-item__img {
      height: 316px;
    }
  }

  .speaker-list-item--small-img {
    .speaker-item__img {
      height: auto;
      padding-top: 100%;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
}

</style>
